import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiUtils } from './api-utils';
import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { FilterCriterion } from '@/app/model/search/filter-criterion';
import { Sorting } from '@/app/model/search/sorting';
import { Page } from '@/app/model/pagination/page';
import { User } from '@/app/model/user/user';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserComplete } from '@/app/model/user/user-complete';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  buildPath(endPoint: string): string {
    return ApiUtils.BASE_PATH + 'users/' + endPoint;
  }

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  createGuestUser(guestUser: any): Promise<ISignUpResult> {
    return Auth.signUp({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      username: guestUser.email,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      password: guestUser.password.newPassword,
      attributes: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        email: guestUser.email,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        given_name: guestUser.firstname,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        family_name: guestUser.lastname,
        locale: this.locale,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        'custom:organization': guestUser.organization,
      },
    });
  }

  getUsers(
    page: number,
    filterCriteria: Array<FilterCriterion>,
    sorting: Sorting
  ): Observable<Page<User> | any> {
    const url = this.buildPath('');
    let queryParams = new HttpParams()
      .set('page', page.toString())
      .set('direction', sorting.direction)
      .set('sortedBy', sorting.sortedBy);
    filterCriteria.forEach(
      (filterCriterion: FilterCriterion) =>
        (queryParams = queryParams.set(
          filterCriterion.key,
          filterCriterion.value
        ))
    );
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: queryParams,
    };
    return this.http
      .get<Page<User>>(url, httpOptions)
      .pipe(
        map((response) => plainToClassFromExist(new Page<User>(User), response))
      );
  }

  confirmSignUp(username: string, code: string): Promise<any> {
    return Auth.confirmSignUp(username, code);
  }

  forgotPassword(email: string): Promise<any> {
    return Auth.forgotPassword(email);
  }

  forgotPasswordSubmit(email: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  async adminGetUserComplete(userId: number): Promise<UserComplete> {
    const url = this.buildPath(`${userId}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await this.http
      .get<any>(url, ApiUtils.HTTP_OPTIONS)
      .toPromise();

    return plainToClass(UserComplete, response);
  }

  adminResendInvitation(userId: number): Promise<void> {
    const url = this.buildPath(`resend-invitation/${userId}`);
    return this.http.post<void>(url, null, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async adminCreateMemberUser(user: User): Promise<User> {
    const url = this.buildPath('');
    const response = await this.http
      .post<User>(url, user, ApiUtils.HTTP_OPTIONS)
      .toPromise();

    return plainToClass(User, response);
  }

  async adminEditMemberUser(user: User): Promise<User> {
    const url = this.buildPath(`${user.id}`);
    const response = await this.http
      .put<User>(url, user, ApiUtils.HTTP_OPTIONS)
      .toPromise();

    return plainToClass(User, response);
  }

  adminDeleteMemberUser(deletedUser: User, newOwner: User): Promise<any> {
    const url = this.buildPath('');
    const options = {
      body: {
        oldUserId: deletedUser.id,
        newOwnerId: newOwner.id,
      },
      headers: ApiUtils.CONTENT_TYPE_JSON_HEADER,
    };
    return this.http.request('delete', url, options).toPromise();
  }

  adminTransferProjects(oldUserId: number, newOwnerId: number): Promise<void> {
    const url = this.buildPath('transfer-projects');
    const body = {
      oldUserId,
      newOwnerId,
    };
    return this.http.post<void>(url, body, ApiUtils.HTTP_OPTIONS).toPromise();
  }

  async superAdminGetMemberUserStatistics(
    localeId: number
  ): Promise<{ filename: string; content: Blob }> {
    const url = this.buildPath('stats/' + localeId.toString());
    const response = (await this.http
      .get(url, ApiUtils.HTTP_OPTIONS_FOR_DOWNLOAD_BLOB)
      .toPromise()) as HttpResponse<Blob>;
    const contentDispoHeader =
      response.headers.get('Content-Disposition') ?? '';
    const filename = contentDispoHeader
      .split(';')[1]
      .trim()
      .split('=')[1]
      .replace(/"/g, '');
    const content = response.body ?? new Blob();
    return { filename, content };
  }
}
